import { createSlice } from '@reduxjs/toolkit';

const setUpMultiLang = (initialValue) => {
  return {
    es_ES: initialValue,
    en_US: initialValue
  };
};

export const setUpInitialState = () => {
  return {
    checkTranslations: false,
    courseTitles: setUpMultiLang(false),
    courseDescriptions: setUpMultiLang(false),
    courseLearningLists: setUpMultiLang([]),
    courseRequirements: setUpMultiLang([]),
    courseCommonMistakesTitles: setUpMultiLang([]),
    courseCommonMistakesDescriptions: setUpMultiLang([]),
    stageTitles: setUpMultiLang(false),
    stageDescriptions: setUpMultiLang(false),
    stageVideoTitles: setUpMultiLang([]),
    stageVideoDescriptions: setUpMultiLang([]),
    stageArticulateTitles: setUpMultiLang([]),
    stageArticulateDescriptions: setUpMultiLang([]),
    stageEvaluationTimes: setUpMultiLang([]),
    stageQuestionTexts: setUpMultiLang([]),
    stageQuestionOptions: setUpMultiLang({}),
    stageQuestionOptionsReasons: setUpMultiLang({}),
    exerciseDescriptions: setUpMultiLang(false),
    exerciseItemNames: setUpMultiLang([]),
    exerciseItemAditionalTexts: setUpMultiLang([]),
    exerciseItemDescriptions: setUpMultiLang({}),
  };
};


export const translateSlice = createSlice({
  name: 'translate',
  initialState: setUpInitialState(),
  reducers: {
    setCheckTranslation: (state, action) => {
      state.checkTranslations = action.payload;
    },
    resetEditCourseStatus: () => {
      return setUpInitialState();
    },
    modifiedState: (state, action) => {
      const { label, locale, value } = action.payload;
      if (value === null) return;
      let item = state[label][locale];
      if (Array.isArray(item)) {
        if (value !== null && !item.includes(value)) {
          item.push(value);
        }
      } else if (typeof item === 'object' && item !== null) {
        const { parentIx, ix } = value;
        if (!item[parentIx])
          item[parentIx] = [ix];
        else if (!item[parentIx].includes(ix)) 
          item[parentIx].push(ix);
      } else {
        state[label][locale] = true;
      }
    },
    changeState: (_, action) => {
      return { ...action.payload };
    }
  }
});


export const { 
  setCheckTranslation,
  resetEditCourseStatus,
  changeState,
  modifiedState,
} = translateSlice.actions;

export default translateSlice.reducer;