
const submitTicket = async ({name, email, subject, content}) => {

  const data = fetch(process.env.REACT_APP_ZENDESK_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'request': {
        'requester': {
          'name': name,
          'email': email
        },
        'subject': subject,
        'comment': {
          'body': content
        }
      }
    })
  }).then(async resp => await resp.json());

  return data;

};

export default submitTicket;