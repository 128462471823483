import React from 'react';
import ReactDOM from 'react-dom';

// Apollo
import { ApolloProvider } from 'react-apollo';
import client from './apollo';


// Router
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Switch } from 'react-router';
import store from './redux/store';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';

// Stylesheets
import 'assets/stylesheets/index.sass';

Sentry.init({
  dsn: 'https://4d5b8fcd0a7c4e6cb0a54d69827aa90b@o1199815.ingest.sentry.io/6323689',
  integrations: [new BrowserTracing()],
  tracesSampleRate:  process.env.REACT_APP_SENTRY_TRACE_RATE || 1.0,
  environment: process.env.REACT_APP_ENV || 'development'
});

Sentry.setContext(
  'extra', {
    platform: process.env.REACT_APP_UNIVERSITY === 'DUOC' ? 'DUOC' : 'UC'
  }
);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          {/*<Route*/}
          {/*  path={'/superadmin'}*/}
          {/*  render={props => <SuperAdmin {...props} client={client} />}*/}
          {/*/>*/}
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Switch>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
