import React from 'react';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
//import LngDetector from 'i18next-browser-languagedetector';
import common_es from './translations/es/common.json';
import onboarding_es from './translations/es/onboarding.json';
import evaluations_es from './translations/es/evaluations.json';
import students_es from './translations/es/students.json';
import notifications_es from './translations/es/notifications.json';
import feedback_es from './translations/es/feedback.json';
import common_en from './translations/en/common.json';
import onboarding_en from './translations/en/onboarding.json';
import evaluations_en from './translations/en/evaluations.json';
import students_en from './translations/en/students.json';
import notifications_en from './translations/en/notifications.json';
import feedback_en from './translations/en/feedback.json';
import error_en from './translations/en/error.json';
import error_es from './translations/es/error.json';
import dashboards_en from './translations/en/dashboards.json';
import dashboards_es from './translations/es/dashboards.json';
import lti_en from './translations/en/lti.json';
import lti_es from './translations/es/lti.json';
import feedback_assistance_en from './translations/en/feedback_assistance.json';
import feedback_assistance_es from './translations/es/feedback_assistance.json';


// import common_pt from './translations/pt/common.json';

import PropTypes from 'prop-types';

const options = {
  // order and from where user language should be detected
  order: [
    'querystring',
    'cookie',
    'localStorage',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
};

const i18n = i18next
  // .use(LngDetector)
  .init({
    lng: localStorage.getItem('locale') || 'es',
    interpolation: { escapeValue: false }, // React already does escaping
    resources: {
      es: {
        common: common_es, // 'common' is our custom namespace
        evaluations: evaluations_es,
        notifications: notifications_es,
        students: students_es,
        feedback: feedback_es,
        error: error_es,
        dashboards: dashboards_es,
        lti: lti_es,
        feedback_assistance: feedback_assistance_es,
        onboarding: onboarding_es,
      },
      en: {
        common: common_en,
        evaluations: evaluations_en,
        notifications: notifications_en,
        students: students_en,
        feedback: feedback_en,
        error: error_en,
        dashboards: dashboards_en,
        lti: lti_en,
        feedback_assistance: feedback_assistance_en,
        onboarding: onboarding_en,
      },

      // pt: {
      //   common: common_pt,
      // },
    },
    detection: options,
  });

class I18nextProviderC extends React.Component {
  render() {
    return <I18nextProvider i18n={i18n}>{this.props.children}</I18nextProvider>;
  }
}

I18nextProviderC.propTypes = {
  children: PropTypes.node,
};

export default I18nextProviderC;
