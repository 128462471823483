// React
import React, { Suspense, lazy } from 'react';

// Router
import { Route, Switch } from 'react-router';
import routes from './routes';

// Components
import LoadingComponent from 'components/Loading';

// Error
const ErrorComponent = lazy(() => import('components/Errors'));

class Router extends React.Component {
  render() {
    return (
      <Suspense fallback={<LoadingComponent />}>
        <Switch>
          {routes.map(({ path, component, exact }) => {
            return (
              <Route path={path} render={component} exact={exact} key={path} />
            );
          })}
          <Route render={() => <ErrorComponent />} />
        </Switch>
      </Suspense>
    );
  }
}

export default Router;
