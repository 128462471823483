import AWS from 'aws-sdk';
import LexRuntimeV2 from 'aws-sdk/clients/lexruntimev2';
import constants from 'config/constants';


const { AUTH_TOKEN } = constants;

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: process.env.REACT_APP_AWS_LEX_IDENTITY_POOL,
});
  
AWS.config.region = process.env.REACT_APP_AWS_LEX_REGION;

const CHATBOT_CONFIG = {
  botAliasId: process.env.REACT_APP_AWS_LEX_ALIAS_ID,
  botId: process.env.REACT_APP_AWS_LEX_BOT_ID,
};

const lexruntimev2 = new LexRuntimeV2(CHATBOT_CONFIG);


const sendMessage = async (text, sessionId, localeId, attr={}) => {

  //const token = Cookies.get(AUTH_TOKEN);
  const token = localStorage.getItem(AUTH_TOKEN);

  const userId = localStorage.getItem('user_id');

  sessionId = userId ? sessionId + '-u' + userId : sessionId;

  const requestAttributes = {
    ...attr,
    token,
    userId
  };

  let params = {
    ...CHATBOT_CONFIG,
    requestAttributes,
    localeId,
    sessionId,
    text
  };
  
  try {
    return await lexruntimev2.recognizeText(params).promise();
  } catch (e) {
    return {
      error: true,
      msg: e
    };
  }
};

const standardizeLocale = (locale) => {
  locale = locale === 'es' ? 'es_ES' : locale;
  locale = locale === 'en' ? 'en_US' : locale;
  return locale;
};

export default lexruntimev2;

export { 
  CHATBOT_CONFIG,
  sendMessage,
  standardizeLocale
};