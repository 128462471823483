// Apollo

import { ApolloClient } from 'apollo-boost';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { withClientState } from 'apollo-link-state';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import history from 'utils/history';
import store from 'redux/store';
import * as Sentry from '@sentry/react';

// Constants
import constants from "config/constants";
const { API_URI, AUTH_TOKEN } = constants;

class GrapQLError extends Error {
  constructor(message, operationName) {
    super(message);
    this.name = `[GraphQLError] ${operationName}`;
  }
}

const httpLink = createHttpLink({
  // uri: `https://api.c1do1.advancedlapp.com/graphql`,
  uri: `${API_URI}/graphql`,
  //credentials: 'include'
});

const authLink = setContext((_, { headers }) => {
  //const token = Cookies.get(AUTH_TOKEN);
  const token = localStorage.getItem(AUTH_TOKEN);

  let locale = localStorage.getItem("locale") || "es_ES";
  locale = locale === "es" ? "es_ES" : locale;
  locale = locale === "en" ? "en_US" : locale;

  return { headers: { authorization: token, locale: locale } };
});

const cache = new InMemoryCache({});

const defaultState = {
  searchText: "",
  categoryFilter: null,
};


const errorLink = onError(({ operation, networkError, graphQLErrors }) => {
  if (!networkError && !graphQLErrors) return;

  let authNeeded = false;
  const query = {
    operationName: operation.operationName,
    variables: operation.variables
  };

  if (graphQLErrors) {
    graphQLErrors.forEach(({ code, message }) => {
      Sentry.captureException(
        new GrapQLError(message, query.operationName), 
        { contexts: {
          query: { ...query, code }
        }});
      if (code === 'AUTHENTICATION_REQUIRED')
        authNeeded = true;
    });
  }
  if (networkError) {
    Sentry.captureException(networkError, 
      { contexts: {
        query
      }});
  }

  if (authNeeded) {
    const ltiPlatform = store.getState()['general'].ltiPlatform;
    let pathname = '/auth';
    if (ltiPlatform)
      pathname = '/courses/error';

    history.push({
      pathname,
      search: 'expiredSession'
    });
    history.go();
  }
});

const stateLink = withClientState({
  cache,
  defaults: defaultState,
  resolvers: {
    Mutation: {
      updateCategoryFilter: (_, { newCategoryFilter }, { cache }) => {
        cache.writeData({ data: { categoryFilter: newCategoryFilter } });
      },
      updateSearchText: (_, { newSearchText }, { cache }) => {
        cache.writeData({ data: { searchText: newSearchText } });
      },
    },
  },
});

const client = new ApolloClient({
  link: ApolloLink.from([stateLink, errorLink, authLink.concat(httpLink)]),
  cache,
});

export default client;
