// utils/GoogleAnalytics.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Route } from 'react-router-dom';

const actions = {
  CREATE_USER: 'Create user',
  CREATE_COURSE: 'Create course',
  CREATE_UNIVERSITY: 'Create university',
  CREATE_ESTABLISHMENT: 'Create establishment',
  CREATE_ANSWER: 'Create answer',
  UNDO_ANSWER: 'Undo answer',
  EVALUATED_ANSWER: 'Evaluated answer',
  SUBSCRIBE_REQUEST: 'Subscribe request',
  UNSUBSCRIBE_REQUEST: 'Unsubscribe request',
  PUBLISHED_COURSE: 'Published courses',
  LOGIN: 'Login',
  LOGIN_ROLE: 'Login by role',
  GET_COURSE: 'Get course',
  SEARCH_COURSE: 'Search by text',
  PVT_COURSE_ID: 'PVT_COURSE_ID_',
  PVT_BU_COURSE_ID: 'PVT_BU_COURSE_ID_',
  PVA_COURSE_ID: 'PVA_COURSE_ID_',
  PVA_BS_COURSE_ID: 'PVA_BS_COURSE_ID_',
  PVA_BT_COURSE_ID: 'PVA_BT_COURSE_ID_',
  PVU_COURSE_ID: 'PVU_COURSE_ID_',
  PVU_BS_COURSE_ID: 'PVU_BS_COURSE_ID_',
  PVU_BT_COURSE_ID: 'PVU_BT_COURSE_ID_',
  PPV_COURSE_ID: 'PPV_COURSE_ID_',
  PCV_COURSE_ID: 'PCV_COURSE_ID_',
  REVIEW_FEEDBACK: 'Review feedback'

};
const categories = {
  USER: 'User',
  COURSE: 'Course',
  ANSWER: 'Answer',
  VIDEO: 'Video',

};

class GoogleAnalytics extends Component {
  componentDidMount () {

    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
  }

  componentDidUpdate ({ location: prevLocation }) {

    const { location: { pathname, search } } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange (pathname, search = '') {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    });

    ReactGA.pageview(page);
  }

  render () {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.object
};

const RouteTracker = () => <Route component={GoogleAnalytics} />;

const init = (options = {}) => {

  const isGAEnabled = process.env.REACT_APP_GOOGLE_ANALYTICS === 'YES';
  const tracking_id = process.env.REACT_APP_GA_TRACKING_ID;

  if (isGAEnabled && typeof tracking_id !== 'undefined') {
    ReactGA.initialize(tracking_id);
    setUserId();
  }

  return isGAEnabled;

};
const Event = (category, action, label, value) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

const setUserId = () => {

  const userId = localStorage.getItem('user_id');
  if(userId !== null && String(userId).trim() !== '') {
    ReactGA.set({ dimension1: userId, userId: userId });
  }

};

export default {
  GoogleAnalytics,
  RouteTracker,
  init,
  Event,
  setUserId,
  actions,
  categories
};
