import { configureStore } from '@reduxjs/toolkit';
import { 
  chatbotReducer,
  courseReducer,
  translateReducer,
  generalReducer,
  surveyReducer
} from './slices';

export default configureStore({
  reducer: {
    chatbot: chatbotReducer,
    course: courseReducer,
    translate: translateReducer,
    general: generalReducer,
    survey: surveyReducer
  }
});