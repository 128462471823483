import { createSlice } from '@reduxjs/toolkit';

export const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    check_survey: false,
    process: 'LOGIN'
  },
  reducers: {
    updateSurvey: (state, action) => {
      state.check_survey = action.payload.check_survey;
      state.process = action.payload.process;
    }
  }
});

export const {
  updateSurvey
} = surveySlice.actions;

export default surveySlice.reducer;