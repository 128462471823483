import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendMessage, standardizeLocale } from './../../utils/lexApi';
import submitTicket from './../../utils/zendeskApi';
import { v4 as uuidv4 } from 'uuid';


export const recognizeMessage = createAsyncThunk(
  'chatbot/recognizeMessage',
  async (req, thunkApi) => {
    let state = await thunkApi.getState();
    let attr = {
      courseId: state.course.id
    };
    let session = state.chatbot.sessionId;
    let message;
    try {
      const res = await sendMessage(req.message, session, state.chatbot.locale, attr);
      message = res.messages[0].contentType === 'CustomPayload' ? JSON.parse(res.messages[0].content) : res.messages[0];
    } catch (err) {
      message = {
        content: 'Bot Error. Try again.'
      };
    }
    return {
      bot: true,
      content: message.content,
      buttons: message.buttons ? message.buttons : [],
    };
  }
);

export const createTicket = createAsyncThunk(
  'chatbot/createTicket',
  async (req, thunkApi) => {
    let data = await submitTicket(req.data);
    if (data.error)
      return thunkApi.rejectWithValue({
        ix: req.ix,
      });    
    return {
      ix: req.ix
    };
  }
);

const initialMessages = {
  'es_ES': 'Hola! En que te puedo ayudar?',
  'en_US': 'Hi! What can I help you with?'
};

export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState: {
    messages: [
      {
        bot: true,
        content: initialMessages[standardizeLocale(localStorage.getItem('locale')) || 'es_ES'],
        buttons: []
      }
    ],
    loading: false,
    locale: standardizeLocale(localStorage.getItem('locale')) || 'es_ES',
    sessionId: uuidv4(),
  },
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    resetMessages: (state) => {
      state.messages = [state.messages[0]];
    },
    changeLocale: (state, action) => {
      state.locale = action.payload; 
    },
    resetChatbot: (state, action) => {
      state.messages = [
        {
          bot: true,
          content: action.payload,
          buttons: [],
        }
      ];
    }
  },
  extraReducers: {
    [recognizeMessage.pending]: (state) => {
      state.loading = true;
    },
    [recognizeMessage.fulfilled]: (state, action) => {
      state.messages.push(action.payload);
      state.loading = false;
    },
    [recognizeMessage.rejected]: (state) => {
      state.messages.push({
        bot: true,
        content: 'Bot Error. Try again.'
      });
      state.loading = false;
    },
    [createTicket.pending]: (state, action) => {
      state.messages[action.meta.arg.ix] = {
        bot: true,
        loading: true
      };
    },
    [createTicket.fulfilled]: (state, action) => {
      state.messages[action.payload.ix] = {
        bot: true,
        content: 'chatbot.zendesk.successSubmit',
        translate: true,
      };
    },
    [createTicket.rejected]: (state, action) => {
      state.messages[action.payload.ix] = {
        bot: true,
        content: 'chatbot.zendesk.failedSubmit',
        translate: true,
      };
    }
  }
});


export const { 
  addMessage,
  resetMessages,
  changeLocale,
  resetChatbot,
  crea
} = chatbotSlice.actions;

export default chatbotSlice.reducer;