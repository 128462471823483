import React from 'react';
import PropTypes from 'prop-types';

import newLogo from './../../../assets/images/new_logo.svg';

// Internationalization
import { translate } from 'react-i18next';

// Style
import './style.sass';

class ServerUnavailable extends React.Component {

  render() {
    const { t } = this.props;

    return (
      <div className='app'>
        <div className='header'>
          <div className='error-navbar'>
            <a href='https://c1do1.ai'>
              <img src={newLogo} className='navbar-logo' style={{height: '30px', width: '132px'}} alt='C1DO1' />
            </a>
          </div>
        </div>

        <div className='body'>
          <span className='title'>{t('error.serverUnavailable.title')}</span>
          <span className='text'>{t('error.serverUnavailable.content')}</span>
          <span className='message'>
            {t('error.serverUnavailable.message')}
          </span>
          <span className='text'>{t('error.serverUnavailable.footer')}</span>
          <img src={newLogo} alt='C1DO1' />
        </div>
      </div>
    );
  }
}

ServerUnavailable.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(ServerUnavailable);
