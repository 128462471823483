import { createSlice } from '@reduxjs/toolkit';


export const courseSlice = createSlice({
  name: 'course',
  initialState: {
    id: '-1',
    name: ''
  },
  reducers: {
    updateCourse: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
    }
  }
});


export const { 
  updateCourse
} = courseSlice.actions;

export default courseSlice.reducer;