import { createSlice } from '@reduxjs/toolkit';


export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    ltiPlatform: false,
  },
  reducers: {
    updateLTIStatus: (state, action) => {
      state.ltiPlatform = action.payload;
    }
  }
});


export const { 
  updateLTIStatus
} = generalSlice.actions;

export default generalSlice.reducer;