// React
import React from 'react';

// Stylesheets
import './style.sass';

const LoadingComponent = () => (
  <div style={{ width: '100%', height: '100%' }} className="loading-container">
    <span className="helper"></span>
    <img alt=''
      src={require('assets/images/loading.gif')}
      className="loading"
    />
  </div>
);

export default LoadingComponent;
